import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { GatsbySeo, BreadcrumbJsonLd } from "gatsby-plugin-next-seo";
import Layout from "~/components/layout";
import PageHeading from "~/components/styled/page-heading";
import TourList from "~/components/tour-list";
import Image from "~/components/image";
import { ChevronRightIcon } from "@heroicons/react/outline";

const RegionPage = ({ data }) => {
	const tours = data.allStrapiTour.edges;
	const flatTours = tours.map(({ node }) => node);
	// const seo = {
	//   title: data.strapiDestination.name,
	// }
	const description = `Discover the best of ${data.strapiRegion.name} on an active adventure.`;
	console.log(description);
	const language = {
		href: `https://www.artofbicycletrips.com/destinations/${data.strapiRegion.name}`,
	};
	console.log(language);
	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title={`THE BEST ${data.strapiRegion.name} Family Adventures`}
				description={`Discover the best of ${data.strapiRegion.name} on our thoughtfully crafted ${data.strapiRegion.name} family adventures. We offer group and private departures on almost all our ${data.strapiRegion.name} family holidays.`}
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: `https://www.artofbicycletrips.in/destinations/${data.strapiRegion.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en-US",
				// 		href: `https://www.artofbicycletrips.com/destinations/${data.strapiRegion.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: `https://www.artofbicycletrips.com/destinations/${data.strapiRegion.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: `https://www.artofbicycletrips.com/destinations/${data.strapiRegion.slug}`,
				// 	},
				// ]}
			/>
			<BreadcrumbJsonLd
				itemListElements={[
					{
						position: 1,
						name: "Destinations",
						item: "https://artofbicycletrips.com/destinations",
					},
					{
						position: 2,
						name: `${data.strapiRegion.destination.name}`,
						item: `https://artofbicycletrips.com/destinations/${data.strapiRegion.destination.slug}`,
					},
					{
						position: 3,
						name: `${data.strapiRegion.name}`,
					},
				]}
			/>
			{data.strapiRegion.image && (
				<div className="">
					<Image
						className="rounded-lg shadow-md transform"
						image={data.strapiRegion.image}
						alt={data.strapiRegion.image.alternativeText}
					/>
				</div>
			)}

			{/* Breadcrumbs */}
			<div className="my-4 flex items-center capitalize whitespace-nowrap overflow-x-auto">
				<Link className="text-xs" to={`/`}>
					Home
				</Link>
				<ChevronRightIcon className="h-4 w-4 text-gray-300 mx-1" />
				<Link className="text-xs" to={`/destinations`}>
					Destinations
				</Link>
				<ChevronRightIcon className="h-4 w-4 text-gray-300 mx-1" />
				<Link
					className="text-xs"
					to={`/destinations/${data.strapiRegion.destination.slug}`}
				>
					{data.strapiRegion.destination.slug}
				</Link>
				<ChevronRightIcon className="h-4 w-4 text-gray-300 mx-1" />
				<span className="text-xs">{data.strapiRegion.name}</span>
			</div>

			<div>
				<div className="text-center">
					<PageHeading>Family Adventures in {data.strapiRegion.name}</PageHeading>
				</div>
				<p className="leading-loose mb-20 font-light md:text-xl md:mx-16 lg:mx-36">
					{data.strapiRegion.desc}
				</p>
				{/* <ReactMarkdown
					className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl mb-14 md:mx-16 lg:mx-36"
					children={data.strapiRegion.detail}
				/> */}
			</div>
			<div>
				<h2 className="mb-10">
					Ease back into a {data.strapiRegion.name} adventure
				</h2>
				<TourList tours={flatTours} />
			</div>
		</Layout>
	);
};

export const query = graphql`
	query RegionQuery($slug: String!) {
		allStrapiTour(
			filter: { regions: { elemMatch: { slug: { eq: $slug } } } }
			sort: { fields: price }
		) {
			edges {
				node {
					id
					duration
					price
					slug
					title
					featuredImage {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
									aspectRatio: 1.3
								)
							}
						}
					}
					slider {
						id
						url
						name
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(aspectRatio: 1.3)
							}
						}
					}

					quickfacts {
						lodging
						level
					}
					activity {
						name
					}
					regions {
						name
						id
					}
					destination {
						name
					}
				}
			}
		}

		strapiRegion(slug: { eq: $slug }) {
			name
			desc
			slug
			detail
			destination {
				name
				slug
			}
			image {
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(aspectRatio: 2.52, layout: FULL_WIDTH)
					}
				}
			}
		}
	}
`;

export default RegionPage;
